import { db, fb } from '..';

export const setPersonalGuidedMindSweep = ({ personalGuidedMindSweepKey, fields }) => {
  const now = Date.now();

  fb.analytics().logEvent('personal-guided-mind-sweeps');

  return db.collection('personal-guided-mind-sweeps').doc(personalGuidedMindSweepKey).set({
    updated: now,
    ...fields,
  }, { merge: true });
};