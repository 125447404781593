import { db, fb } from '..';
import moment from 'moment';
import { setMindSweep } from '../mind-sweeps/write';

export const createMessage = ({ messageKey, fields, mindSweep }) => {

  fb.analytics().logEvent('create_message');

  const now = Date.now();
  const { key: mindSweepKey } = mindSweep || {};

  if (
    !mindSweep
    || !mindSweepKey
    || !fields
    || !fields.text
    || !fields.uid
  ) {
    return Promise.reject(new Error('fail'));
  }

  const message = {
    updated: now,
    ...fields,
  }

  return db.collection('messages').doc(messageKey).set(message, { merge: true })
    .then(() => {

      const mindSweepFields = {
        lastMessage: message,
      }
    
      // 
      if (!mindSweep.title) {
        mindSweepFields.created = Date.now();
        mindSweepFields.title = `Quick chat — ${moment().format('D-MM-YYYY HH:mm')}`;
        mindSweepFields.page = 'inbox';
        mindSweepFields.uid = fields.uid;
      }

      return setMindSweep({
        mindSweepKey,
        fields: mindSweepFields,
      })
    })
};