import React, { useContext, useState } from 'react';
import { logout } from '../../../database/auth';
import { CurrentMindSweepContext, UserContext } from '../../../database/context';
import { Link, matchPath, useLocation } from 'react-router-dom';
import EditTitleModal from './edit-title.modal';


function MindsweepTopBar() {
  const { data: mindSweep } = useContext(CurrentMindSweepContext);
  const { title } = mindSweep || {};
  const { user: { email } } = useContext(UserContext);
  const [editTitle, setEditTitle] = useState(false);
  const location = useLocation();
  const isArchive = matchPath(location.pathname, {
    path: "/archive/:mindSweepKey",
  });

  return (
    <>
      {
        !!editTitle
        && <EditTitleModal title={title} setEditTitle={setEditTitle} />
      }
      <div>
        <div className="h-12 bg-white border-b text-gray-700 flex w-full items-center">
          <div
            className="tex-left flex-grow leading-none focus:outline-none flex items-center p-3 truncate"
          >
            <Link
              to={
                isArchive
                  ? '/archive'
                  : '/'
              }
            >
              <i className="fas fa-arrow-left mr-5"></i>
            </Link>
            <div className="ml-2 text-xs text-gray-500">
              { email }
            </div>
          </div>
          
          <button
            type="button"
            className="flex-no-shrink p-4 leading-none focus:outline-none xl:hidden"
            onClick={() => {
              logout();
            }}
          >
            <i className="far fa-sign-out"></i>
          </button>
        </div>
      </div>
    </>
  );
}


export default MindsweepTopBar;
