import React from 'react';
import firebase from 'firebase/app';


export function useMessages({ mindSweepKey, uid }) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      setLoading(true);
      setError(false);

      const unsubscribe = firebase
        .firestore()
        .collection('messages')
        .where('uid', '==', uid)
        .where('mindSweepKey', '==', mindSweepKey)
        .orderBy('created', 'desc')
        .onSnapshot((snapshot) => {
          const messages = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            messages.unshift({
              key: doc.id,
              ...data,
            });
          });
          console.log('messages')
          setData(messages);
          console.log('setLoading')
          setLoading(false);
          console.log('data')
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [mindSweepKey, uid],
  );

  return {
    error,
    loading,
    data,
  };
}