import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import TopBar from '../top-bar';
import MindSweeps from '../mind-sweeps';
import { UserContext } from '../../../database/context';
import { useArchivedMindSweeps } from '../../../database/mind-sweeps/read';


function Archive() {
  const { mindSweepKey } = useParams();
  const { user } = useContext(UserContext);
  const mindSweepsQuery = useArchivedMindSweeps({ uid: user.uid });


  return (
    <div className="flex-1 w-full h-full flex bg-gray-200 xl:p-10">
      <div className="bg-slate-50 w-full h-full xl:max-w-4xl mx-auto flex flex-col relative shadow-xl">
        <div className="flex-shrink-0">
          <TopBar />
        </div>
        <div className="w-full overflow-auto scrolling-touch px-3 pt-5">
          <div className="w-full">
            <div className="grid grid-cols-2 gap-3">
              <Link to="/" className="bg-slate-200 hover:bg-slate-300 p-3 rounded-xl flex items-center text-gray-700">
                <div>Home</div>
                <i className="ml-auto fa-regular fa-house"></i>
              </Link>
              <div className="bg-blue-700 text-white p-3 rounded-xl flex items-center">
                <div className="">Archive</div>
                <i className="ml-auto fa-regular fa-box-archive text-lg"></i>
              </div>
            </div>
            <hr className="my-3" />
            <MindSweeps
              mindSweepsQuery={mindSweepsQuery}
              mindSweepKey={mindSweepKey}
              page="archive"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Archive;
