import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { setMindSweep } from '../../../database/mind-sweeps/write';
import { createMessage } from '../../../database/messages/create';
import { usePersonalGuidedMindSweeps } from '../../../database/personal-guided-mind-sweep/read';
import { UserContext } from '../../../database/context';
import { deletePersonalGuidedMindSweep } from '../../../database/personal-guided-mind-sweep/delete';


function PersonalMindSweep() {
  const { user } = useContext(UserContext);
  const { uid } = user;
  const history = useHistory();
  const { loading, data: personalGuidedMindSweeps } = usePersonalGuidedMindSweeps({ uid: user.uid });

  if (loading) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }


  const startMindsweep = (personalGuidedMindSweep) => {
    const mindSweepKey = uuidv4();

    /*
    mindsweeps['emotional-mindsweep'] = {
      name: 'Emotional Checkup',
      totalMinutes: 5,
      language: 'en',
      questions: [
        { question: 'How are you feeling, mentally, and physically?', minutes: 1 },
        { question: 'What is bothering you?', minutes: 1 },
        { question: 'What can you change, and what do you have to accept?', minutes: 1 },
        { question: 'What can you do now or today to cheer yourself up?', minutes: 1 },
        { question: 'What are you thankful for?', minutes: 1 },
      ]
    }
    */


    const title = `${personalGuidedMindSweep.form.name} — ${moment().format('D-MM-YYYY HH:mm')}`;
    const fields = {
      title,
      uid,
      currentQuestion: 0,
      startTime: Date.now(),
      guided: personalGuidedMindSweep.form,
    }

    setMindSweep({
      mindSweepKey,
      fields,
    });

    // Add message with first question
    sendFirstQuestion({ mindSweepKey, text: personalGuidedMindSweep.form.questions[0].question, title });

    history.push({
      pathname: `/m/${mindSweepKey}`,
      state: { typing: true }
    });
  }

  const sendFirstQuestion = ({ mindSweepKey, text, title }) => {

    const fields = {
      uid,
      mindSweepKey,
      created: Date.now(),
      text,
      question: true,
    }

    createMessage({
      messageKey: uuidv4(),
      fields,
      mindSweep: { key: mindSweepKey, title },
    })
    .catch((error) => {
      console.error(error);
    });
  }
  

  return (
    <div className='mb-5'>
      <h2 className='font-bold mb-2 text-lg text-gray-800'>Personal Guided Mind Sweeps</h2>
      <div className="grid xl:grid-cols-2 gap-3">
        {
          !!personalGuidedMindSweeps
          && !!personalGuidedMindSweeps.length
          && personalGuidedMindSweeps.map((personalGuidedMindSweep) => {
            const { key, form } = personalGuidedMindSweep;
            const { name, url, totalMinutes, numberOfQuestions } = form;

            return (
              <div key={key} className="bg-white rounded-xl relative shadow hover:shadow-lg">
                <button
                  type="button"
                  className="block w-full text-left text-white bg-center bg-cover overflow-hidden rounded-t-xl"
                  style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${url})`}}
                  onClick={() => { startMindsweep(personalGuidedMindSweep) }}
                >
                  <div className="p-3 flex items-center">
                    <div>
                      <div className="font-bold">{ name }</div>
                      <div className="text-sm ext-gray-200 xl:mt-5">{`${numberOfQuestions} questions ~ ${totalMinutes} minutes `}</div>
                    </div>
                    <i className="ml-auto far fa-play text-lg"></i>
                  </div>
                </button>
                <div className="rounded-b-xl overflow-hidden border-t grid grid-cols-2 divide-x text-gray-600 bg-gray-100 text-sm">
                  <button
                    className="hover:bg-gray-200 px-3 py-2" 
                    onClick={() => {
                      const sure = window.confirm('Are you sure?');
                      if (!sure) {
                        return;
                      }
                      deletePersonalGuidedMindSweep({ personalGuidedMindSweepKey: key });
                    }}
                  >
                    <i className="far fa-trash mr-2"></i> Delete
                  </button>
                  <Link className="hover:bg-gray-200 px-3 py-2 text-center" to={`/create-guided-mind-sweep/${key}`}>
                  <i className="fa-regular fa-pencil mr-2"></i> Edit
                  </Link>
                </div>
              </div>
            )
          })
        }
        <Link
          to={`/create-guided-mind-sweep/${uuidv4()}`}
        >
          <div className="text-left bg-white p-3 rounded-xl flex items-center text-gray-800 bg-center bg-cover shadow hover:shadow-lg">
            <div className="font-bold py-3">Create a personal Guided Mind Sweep</div>
            <i className="ml-auto far fa-plus text-lg"></i>
          </div>
        </Link>
      </div>
    </div>
  )
}


export default PersonalMindSweep;
