import React from 'react';
import MindSweep from './mind-sweep';


function MindSweeps(props) {
  const { mindSweepsQuery, mindSweepKey } = props;
  const { loading, data: mindSweeps } = mindSweepsQuery;

  if (loading) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  return (
    <div className="grid xl:grid-cols-2 gap-3">
      {
        !!mindSweeps
        && !!mindSweeps.length
        && mindSweeps.map((mindSweep) => {
          return <MindSweep key={mindSweep.key} currentMindSweep={mindSweepKey} mindSweep={mindSweep} />
        })
      }
    </div>
  )
}


export default MindSweeps;
