import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { CurrentMindSweepContext, UserContext } from '../../../database/context';
import { createMessage } from '../../../database/messages/create';
import { setMindSweep } from '../../../database/mind-sweeps/write';

function QuestionList(props) {
  const modalNode = React.useRef();
  const { setQListModalOpen, inputElement, setIsQuestion } = props;
  const { data: mindSweep } = useContext(CurrentMindSweepContext);
  const { user: { uid } } = useContext(UserContext);
  const { mindSweepKey } = useParams();

  document.body.classList.add('overflow-hidden');

  const mindsweeps = {}
  mindsweeps['professional-mindsweep'] = {
    name: 'After Work',
    totalMinutes: 5,
    language: 'en',
    questions: [
      { question: 'What did you do that made you feel proud?', minutes: 1 },
      { question: 'What feedback would you give yourself?', minutes: 1 },
      { question: 'What are your most important tasks left unfinished?', minutes: 1 },
      { question: 'What big projects are on your mind?', minutes: 1 },
      { question: "What's the first task you are going to work on next?", minutes: 1 },
      // waiting for, financial
    ]
  }
  mindsweeps['personal-mindsweep'] = {
    name: 'Personal Checkup',
    totalMinutes: 5,
    language: 'en',
    questions: [
      { question: 'What open commitments do you have to your spouse, partner, children, parents, family, and friends?', minutes: 1 },
      { question: 'Any friends and or family members you miss or want to get it touch with?', minutes: 1 },
      { question: 'Are your personal finances and administration clear?', minutes: 1 },
      { question: "What's the biggest dream on your mind right now?", minutes: 1 },
      { question: "What's the next step in making that dream a reality?", minutes: 1 },
    ]
  }
  mindsweeps['emotional-mindsweep'] = {
    name: 'Emotiona Mind Sweep',
    totalMinutes: 5,
    language: 'en',
    questions: [
      { question: 'How are you feeling, mentally, and physically?', minutes: 1 },
      { question: 'What is bothering you?', minutes: 1 },
      { question: 'What can you change, and what do you have to accept?', minutes: 1 },
      { question: 'What can you do now or today to cheer yourself up?', minutes: 1 },
      { question: 'What are you thankful for?', minutes: 1 },
    ]
  }

  const startMindsweep = (mindsweep) => {
    setMindSweep({
      mindSweepKey,
      fields: {
        uid,
        currentQuestion: 0,
        startTime: Date.now(),
        guided: mindsweeps[mindsweep]
      }
    });

    // Add message with first question
    sendFirstQuestion({ text: mindsweeps[mindsweep].questions[0].question });
  }

  const sendFirstQuestion = ({ text }) => {

    const fields = {
      uid,
      mindSweepKey,
      created: Date.now(),
      text,
      question: true,
    }

    createMessage({
      messageKey: uuidv4(),
      fields,
      mindSweep,
    })
    .catch((error) => {
      console.error(error);
    });

    setQListModalOpen(false);
    setIsQuestion(false);
    inputElement.current.focus();
  }


  React.useEffect(() => {
    const handleClick = (e) => {
      if (modalNode.current.contains(e.target)) {
        return;
      }
      document.body.classList.remove('overflow-hidden');
      setQListModalOpen(false);
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.body.classList.remove('overflow-hidden');
    };
  }, [setQListModalOpen]);

  return (
    <div
      className="fixed overflow-auto w-full h-full inset-0 z-50 flex flex-col justify-end xl:justify-center items-center xl:items-center px-10"
      style={{ background: 'rgba(0,0,0,0.7)' }}
    >
      <div
        ref={modalNode}
        className="rounded-lg shadow w-full xl:w-1/2 mx-auto overflow-hidden"
      >
        <div className="bg-teal-700 text-white rounded-t-lg p-5 text-lg font-bold">
          Start a guided Mind Sweep
        </div>
        <div className="bg-white p-5 text-sm xl:text-base">
          <button
            type="button"
            className="block w-full px-2 py-1 rounded-lg mb-2 bg-gray-700 text-left text-white hover:bg-gray-800 focus:outline-none"
            onClick={() => { startMindsweep('professional-mindsweep') }}
          >
            Professional Mind Sweep
          </button>
          <button
            type="button"
            className="block w-full px-2 py-1 rounded-lg mb-2 bg-gray-700 text-left text-white hover:bg-gray-800 focus:outline-none"
            onClick={() => { startMindsweep('personal-mindsweep') }}
          >
            Personal Mind Sweep
          </button>
          <button
            type="button"
            className="block w-full px-2 py-1 rounded-lg mb-2 bg-gray-700 text-left text-white hover:bg-gray-800 focus:outline-none"
            onClick={() => { startMindsweep('emotional-mindsweep') }}
          >
            Emotional Mind Sweep
          </button>
          <button
            type="button"
            className="hidden block w-full px-2 py-1 rounded-lg mb-2 bg-gray-700 text-left text-white hover:bg-gray-800 focus:outline-none"
            onClick={() => { startMindsweep('full-mindsweep') }}
          >
            Full Mind Sweep
          </button>
        </div>
      </div>
      <div className="w-full p-5 flex xl:hidden justify-center text-white text-lg">
        <i className="far fa-times"></i>
      </div>
    </div>
  );
}

export default QuestionList;
