import React from 'react';
import TopBar from '../top-bar';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import GuidedMindsweeps from './guided-mindsweeps';
import PersonalMindSweep from './personal-mindsweeps';

function Home() {
  const history = useHistory();

  return (
    <div className="flex-1 w-full h-full flex bg-gray-200 xl:p-10">
      <div className="bg-slate-50 w-full h-full xl:max-w-4xl mx-auto flex flex-col relative shadow-xl">
        <div className="flex-shrink-0">
          <TopBar />
        </div>

        <div className="overflow-auto scrolling-touch px-3 pt-5">
          <div className="grid grid-cols-2 gap-3">
            <div className="bg-blue-700 text-white p-3 rounded-xl flex items-center">
              <div>Home</div>
              <i className="ml-auto fa-regular fa-house"></i>
            </div>
            <Link to="/archive" className="bg-slate-200 hover:bg-slate-300 p-3 rounded-xl flex items-center text-gray-700">
              <div className="">Archive</div>
              <i className="ml-auto fa-regular fa-box-archive text-lg"></i>
            </Link>
          </div>

          <hr className="my-3" />

          <PersonalMindSweep />

          <GuidedMindsweeps />

        </div>

        <div className="mt-auto flex-shrink-0 p-3">
          <button
            className="block w-full bg-slate-200 p-3 rounded-xl text-gray-700 flex items-center focus:outline-none"
            type="button"
            onClick={() => {
              history.push({
                pathname: `/m/${uuidv4()}`,
                state: { typing: true }
              });
            }}
          >
            <div className="font-bold">Quick chat</div>
            <i className="ml-auto fas fa-arrow-right text-lg"></i>
          </button>
        </div>
      </div>
    </div>
  )
}


export default Home;
