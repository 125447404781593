import React, { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize';
import { CurrentMindSweepContext, UserContext } from '../../../database/context';
import { useParams } from 'react-router-dom';
import { sendDigestMail } from '../../../database/email';
import { createMessage } from '../../../database/messages/create';


function InputBar(props) {
  const {
    inputElement,
    isQuestion,
    setIsQuestion,
    typing,

  } = props;
  const { data: mindSweep } = useContext(CurrentMindSweepContext);
  const { user: { uid, email } } = useContext(UserContext);
  const { mindSweepKey } = useParams();
  const [input, setInput] = React.useState('');
  const [sending, setSending] = useState(false);

  const sendMessage = ({
    text,
    question = false,
    email = false,
  }) => {
    if (!text) {
      return;
    }

    const fields = {
      uid,
      mindSweepKey,
      created: Date.now(),
      text,
      question,
      email,
    }

    createMessage({
      messageKey: uuidv4(),
      fields,
      mindSweep,
    })
    .then(() => {
      setSending(false);
    })
    .catch((error) => {
      console.error(error);
    });

    if (isQuestion) {
      setIsQuestion(false);
    }

    setInput('');

    inputElement.current.focus();
  }

  return (
    <div>
      <div className="flex items-end leading-none p-2">

        <div
          className="flex-grow bg-white py-3 px-5 shadow relative flex items-stretch"
          style={{
            borderTopLeftRadius: '1rem',
            borderBottomLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
            borderBottomRightRadius: '1rem',
          }}
        >
          <TextareaAutosize
            minRows={1}
            maxRows={3}
            inputRef={inputElement}
            onChange={e => setInput(e.target.value)}
            value={input}
            className="focus:outline-none block w-full appearance-none leading-normal"
            type="text"
            placeholder="Type a message"
            style={{
              resize: 'none'
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey) {
                return;
              }
              if (e.key === 'Enter') {
                e.preventDefault();
                sendMessage({
                  text: input.replace(/\s*$/,''),
                  question: isQuestion,
                });
              }
            }}
            autoFocus={!!typing}
          />
        </div>
        <div className="flex items-center">

          {
            !!sending
            && (
              <div className="ml-2 w-12 h-12 bg-gray-200 rounded-full flex justify-center items-center content-center focus:outline-none">
                <i className="fas fa-spinner-third fa-spin text-gray-500 text-lg"></i>
              </div>
            )
          }

          {
            !sending
            && !input
            && (
              <button
                type="button"
                className="shadow ml-2 w-12 h-12 bg-blue-700 hover:bg-blue-800 rounded-full flex justify-center items-center content-center focus:outline-none"
                onClick={() => {


                  setSending(true);
                  sendDigestMail({ mindSweepKey, email })
                  .then(() => {
                    setSending(false);

                    // create message
                    return sendMessage({
                      text: `Digest email sent: ${moment().format('D-MM-YYYY HH:mm')}`,
                      question: false,
                      email: true,
                    });
                  })
                  .catch((error) => {
                    console.log('Error, contact Oscar.');
                    setSending(false);
                  });
                }}
              >
                <i className="far fa-envelope text-white text-xl" />
              </button>
            )
          }

          {
            !sending
            && !!input
            && (
              <button
                type="button"
                className="shadow ml-2 w-12 h-12 bg-blue-700 hover:bg-blue-800 rounded-full flex justify-center items-center content-center focus:outline-none"
                onClick={() => {

                  if (!input || !input.length) {
                    return;
                  }

                  setSending(true);
                  sendMessage({
                    text: input.replace(/\s*$/,''),
                    question: isQuestion,
                  });
                }}
              >
                <i className="fas fa-paper-plane text-white text-xl" />
              </button>
            )
          }
        </div>
      </div>
    </div>
  );
}


export default InputBar;
