import moment from 'moment';
import React, { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../../database/context';
import { sendDigestMail } from '../../../database/email';
import { createMessage } from '../../../database/messages/create';
import { deleteMindSweep } from '../../../database/mind-sweeps/delete';


function MindSweep({ currentMindSweep, mindSweep }) {
  const { key, title, lastMessage } = mindSweep;
  const history = useHistory();
  const { user: { uid, email } } = useContext(UserContext);
  const [sending, setSending] = useState(false);
  const [deleting, setDeleting] = useState(false);


  const sendMessage = ({
    text,
    question = false,
    email = false,
  }) => {
    if (!text) {
      return;
    }

    const fields = {
      uid,
      mindSweepKey: key,
      created: Date.now(),
      text,
      question,
      email,
    }
    
    createMessage({
      messageKey: uuidv4(),
      fields,
      mindSweep,
    })
    .then(() => {
      setSending(false);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  return (
    <div
      key={key}
      className={`
        rounded-lg shadow flex flex-col justify-between hover:shadow-lg overflow-hidden
        ${
          currentMindSweep === key ? 'text-white bg-denim-700' : 'text-gray-700 bg-white'
        }
        
      `}
    >
      <Link
        className="block w-full p-3"
        to={`/archive/${key}`}
      >
        <div className="text-left">
          {
            !!title
            && (
              <div className="font-bold break-words">{ title }</div>
            )
          }
          <div className="text-sm italic break-words">
            {
              !!lastMessage
              && lastMessage.text
            }
          </div>
        </div>
      </Link>
      <div className="flex border-t bg-gray-100 rounded-b-lg text-gray-600 text-sm ">
        <button
          className="block w-full hover:bg-gray-200 rounded-bl-lg p-2 flex-1 text-center focus:outline-none"
          onClick={() => {
            const sure = window.confirm('Are you sure?');
            if (!sure) {
              return;
            }
            setDeleting(true);
            deleteMindSweep({
              mindSweepKey: key,
            })
            .then(() => {
              history.push('/archive');
            })
          }}
        >

          {
            !!deleting
            && (
              <i className="fas fa-spinner-third fa-spin"></i>
            )
          }

          {
            !deleting
            && (
              <>
              <i className="far fa-trash mr-2"></i> Delete
              </>
            )
          }
        </button>
        <button
          className="block w-full hover:bg-gray-200 border-l rounded-br-lg p-2 flex-1 text-center focus:outline-none"
          onClick={() => {

            console.log('send')

            if (sending) {
              return;
            }

            setSending(true);

            // email
            sendDigestMail({ mindSweepKey: key, email })
            .then(() => {
              setSending(false);

              // create message
              return sendMessage({
                text: `Digest email sent: ${moment().format('D-MM-YYYY HH:mm')}`,
                question: false,
                email: true,
              });
            })
            .catch((error) => {
              console.log('Error, contact Oscar.');
              setSending(false);
            });

          }}
        >

          {
            !!sending
            && (
              <i className="fas fa-spinner-third fa-spin"></i>
            )
          }

          {
            !sending
            && (
              <>
              <i className="far fa-envelope mr-2"></i> Mail to me
              </>
            )
          }
        </button>
      </div>
    </div>
  )
}


export default MindSweep;
