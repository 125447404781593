import React, { useContext } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../../../database/context';
import { createMessage } from '../../../database/messages/create';
import { setMindSweep } from '../../../database/mind-sweeps/write';

function GuidedMindsweeps() {
  const { user: { uid } } = useContext(UserContext);
  const history = useHistory();

  const mindsweeps = {}
  mindsweeps['professional-mindsweep'] = {
    name: 'After Work',
    totalMinutes: 5,
    language: 'en',
    questions: [
      { question: 'What did you do that made you feel proud?', minutes: 1 },
      { question: 'What feedback would you give yourself?', minutes: 1 },
      { question: 'What are your most important tasks left unfinished?', minutes: 1 },
      { question: 'What big projects are on your mind?', minutes: 1 },
      { question: "What's the first task you are going to work on next?", minutes: 1 },
      // waiting for, financial
    ]
  }
  mindsweeps['personal-mindsweep'] = {
    name: 'Personal Checkup',
    totalMinutes: 5,
    language: 'en',
    questions: [
      { question: 'What open commitments do you have to your spouse, partner, children, parents, family, and friends?', minutes: 1 },
      { question: 'Any friends and or family members you miss or want to get it touch with?', minutes: 1 },
      { question: 'Are your personal finances and administration clear?', minutes: 1 },
      { question: "What's the biggest dream on your mind right now?", minutes: 1 },
      { question: "What's the next step in making that dream a reality?", minutes: 1 },
    ]
  }
  mindsweeps['emotional-mindsweep'] = {
    name: 'Emotional Checkup',
    totalMinutes: 5,
    language: 'en',
    questions: [
      { question: 'How are you feeling, mentally, and physically?', minutes: 1 },
      { question: 'What is bothering you?', minutes: 1 },
      { question: 'What can you change, and what do you have to accept?', minutes: 1 },
      { question: 'What can you do now or today to cheer yourself up?', minutes: 1 },
      { question: 'What are you thankful for?', minutes: 1 },
    ]
  }


  const startMindsweep = (mindsweep) => {
    const mindSweepKey = uuidv4();

    const title = `${mindsweeps[mindsweep].name} — ${moment().format('D-MM-YYYY HH:mm')}`;
    const fields = {
      title,
      uid,
      currentQuestion: 0,
      startTime: Date.now(),
      guided: mindsweeps[mindsweep]
    }

    setMindSweep({
      mindSweepKey,
      fields,
    });

    // Add message with first question
    sendFirstQuestion({ mindSweepKey, text: mindsweeps[mindsweep].questions[0].question, title });

    history.push({
      pathname: `/m/${mindSweepKey}`,
      state: { typing: true }
    });
  }

  const sendFirstQuestion = ({ mindSweepKey, text, title }) => {

    const fields = {
      uid,
      mindSweepKey,
      created: Date.now(),
      text,
      question: true,
    }

    createMessage({
      messageKey: uuidv4(),
      fields,
      mindSweep: { key: mindSweepKey, title },
    })
    .catch((error) => {
      console.error(error);
    });
  }

  return (
    <div>
      <h2 className='font-bold mb-2 text-lg text-gray-800'>Official Guided Mind Sweeps</h2>
      <div className="grid xl:grid-cols-2 gap-3">
        <button
          type="button"
          className="text-left bg-slate-200 p-3 rounded-xl flex items-center text-white bg-center bg-cover shadow hover:shadow-lg"
          style={{ backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://images.unsplash.com/photo-1554415707-6e8cfc93fe23?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)'}}
          onClick={() => { startMindsweep('professional-mindsweep') }}
        >
          <div className="">
            <div className="font-bold">After work</div>
            <div className="text-sm xl:mt-5">5 questions ~ 5 minutes</div>
          </div>
          <i className="ml-auto far fa-play text-lg"></i>
        </button>
        <button
          type="button"
          className="text-left bg-slate-200 p-3 rounded-xl flex items-center text-white bg-center bg-cover shadow hover:shadow-lg"
          style={{ backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(https://images.unsplash.com/photo-1471958680802-1345a694ba6d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)'}}
          onClick={() => { startMindsweep('personal-mindsweep') }}
        >
          <div className="">
            <div className="font-bold">Personal checkup</div>
            <div className="text-sm xl:mt-5">5 questions ~ 5 minutes</div>
          </div>
          <i className="ml-auto far fa-play text-lg"></i>
        </button>
        <button
          type="button"
          className="text-left bg-slate-200 p-3 rounded-xl flex items-center text-white bg-center bg-cover shadow hover:shadow-lg"
          style={{ backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(https://images.unsplash.com/photo-1488188840666-e2308741a62f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1748&q=80)'}}
          onClick={() => { startMindsweep('emotional-mindsweep') }}
        >
          <div className="">
            <div className="font-bold">Emotional checkup</div>
            <div className="text-sm xl:mt-5">5 questions ~ 5 minutes</div>
          </div>
          <i className="ml-auto far fa-play text-lg"></i>
        </button>
      </div>
    </div>
  )
}


export default GuidedMindsweeps;
