import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { UserContext } from '../../../database/context';
import { setPersonalGuidedMindSweep } from '../../../database/personal-guided-mind-sweep/write';

function CreateGuidedMindSweepForm({ personalGuidedMindSweep }) {
  const { personalGuidedMindSweepKey } = useParams();
  const { user } = useContext(UserContext);
  const { uid } = user;
  const history = useHistory();
  const [form, setForm] = useState({
    name: '',
    url: '',
    questions: [
      {
        question: '',
        minutes: 1,
      },
      {
        question: '',
        minutes: 1,
      },
      {
        question: '',
        minutes: 1,
      },
      {
        question: '',
        minutes: 1,
      },
    ]
  });


  useEffect(() => {
    if (personalGuidedMindSweep && personalGuidedMindSweep.form) {
      setForm(personalGuidedMindSweep.form)
    }
  }, [personalGuidedMindSweep])

  const handleInputChange = (event) => {
    event.persist();
    setForm(inputs => ({...inputs, [event.target.name]: event.target.value}));
  }

  return (
    <>
      <div className="overflow-auto scrolling-touch px-3 pt-5">
        <div className="grid grid-cols-2 gap-3">
          <Link to="/" className="bg-slate-200 hover:bg-slate-300 p-3 rounded-xl flex items-center text-gray-700">
            <div>Home</div>
            <i className="ml-auto fa-regular fa-house"></i>
          </Link>
        </div>

        <hr className="my-3" />

        <div>
          <label htmlFor="name" className="block font-bold mb-1 text-gray-800">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={form.name}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-3"
            placeholder="Name"
            onChange={handleInputChange}
          />
        </div>
        <div className="mt-5">
          <label htmlFor="url" className="block font-bold mb-1 text-gray-800">Image url</label>
          <div className="mb-2 text-gray-700">We recommend copy-pasting an <a href="http://unsplash.com" target="_blank" rel="noopener noreferrer" className="underline">unsplash.com</a> image address.</div>
          <input
            type="text"
            id="url"
            name="url"
            value={form.url}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-3"
            placeholder="Image url"
            onChange={handleInputChange}
          />
        </div>
        <div>
        <div className="mt-5 hidden">
          <label htmlFor="numberOfQuestions" className="block font-bold mb-1 text-gray-800">Number of questions</label>
          <input
            type="number"
            id="numberOfQuestions"
            name="numberOfQuestions"
            value={form.numberOfQuestions}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-3"
            placeholder="Image url"
            onChange={(event) => {
              event.persist();
              setForm(inputs => ({...inputs, [event.target.name]: parseInt(event.target.value)}));
            }}
          />
          <pre>{JSON.stringify(form.questions.length, null, 2) }</pre>
        </div>
        </div>

        <div className="mt-5">
          <label htmlFor="name" className="block font-bold mb-2 text-gray-800">Questions and minutes</label>
          {
            form.questions.map((_, i) => {
              return (
                <div key={`question_${i}`} className="grid grid-cols-5 gap-3 mb-2">
                  <input
                    type="text"
                    id="question"
                    name="question"
                    value={form.questions[i] && form.questions[i].question ? form.questions[i].question : ''}
                    className="col-span-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-3"
                    placeholder="Question"
                    onChange={(event) => {
                      event.persist();
                      const newQuestions = [...form.questions];
                      newQuestions[i] = {
                        ...newQuestions[i],
                        question: event.target.value
                      };
                      setForm(inputs => ({...inputs, questions: newQuestions}));
                    }}
                  />
                  <input
                    type="number"
                    id="minutes"
                    name="minutes"
                    value={form.questions[i] && form.questions[i].minutes ? form.questions[i].minutes : ''}
                    className="col-span-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-3"
                    placeholder="Minutes"
                    onChange={(event) => {
                      const newNumber = parseInt(event.target.value, 10) ? parseInt(event.target.value, 10) : 0;
                      event.persist();
                      const newQuestions = [...form.questions];
                      newQuestions[i] = {
                        ...newQuestions[i],
                        minutes: newNumber,
                      };
                      setForm(inputs => ({...inputs, questions: newQuestions}));
                    }}
                  />
                  <button
                    typeof='button'
                    className="col-span-1 bg-slate-200 hover:bg-slate-300 p-3 text-sm w-full rounded-xl text-gray-700 text-center"
                    onClick={() => {
                      const newQuestions = [...form.questions];
                      newQuestions.splice(i,1);
                      setForm(inputs => ({...inputs, questions: newQuestions}));
                    }}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
              );
            })
          }
          <div className='grid grid-cols-5 gap-3'>
            <button
              type='button'
              className='col-span-4 bg-slate-200 hover:bg-slate-300 p-3 text-sm w-full rounded-xl flex items-center text-gray-700'
              onClick={() => {
                const newQuestions = [...form.questions];
                newQuestions.push({
                  question: '',
                  minutes: 1,
                })
                setForm(inputs => ({...inputs, questions: newQuestions}));
              }}
            >
              Add question
              <i className="ml-auto fa-regular fa-plus"></i>
            </button>
          </div>
        </div>

      </div>
      <div className="mt-auto flex-shrink-0 p-3">
        <button
          className="block w-full bg-blue-700 hover:bg-blue-800 p-3 rounded-xl text-white flex items-center focus:outline-none"
          type="button"
          onClick={() => {

            form.numberOfQuestions = form.questions.length;

            form.totalMinutes = 0;
            form.questions.forEach((formQuestion, i) => {
              form.totalMinutes += formQuestion.minutes ? parseInt(formQuestion.minutes, 10) : 1;
          
              if (!formQuestion.minutes) {
                form.questions[i].minutes = 1;
              }
            });

            setPersonalGuidedMindSweep({
              personalGuidedMindSweepKey,
              fields: { form, uid },
            })
            .then(() => {
              history.push('/');
            })
          }}
        >
          <div className="font-bold text-center w-full">Save Guided Mind Sweep</div>
        </button>
      </div>
    </>
  )
}

export default CreateGuidedMindSweepForm;