import React from 'react';
import firebase from 'firebase/app';


// Read 1 Mind Sweep
export function useMindSweep({ mindSweepKey, uid }) {

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);


  React.useEffect(
    () => {
      setLoading(true);
      setError(false);

      const unsubscribe = firebase
        .firestore()
        .collection('mind-sweeps')
        .doc(mindSweepKey)
        .onSnapshot((doc) => {
          const data = doc.data();
          setData({
            key: doc.id,
            exists: doc.exists,
            ...data,
          });
          setLoading(false);
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [mindSweepKey, uid],
  );

  return {
    error,
    loading,
    data,
  };
}


// List inbox Mind Sweeps
export function useInboxMindSweeps({ uid }) {

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);


  React.useEffect(
    () => {
      setLoading(true);
      setError(false);

      const unsubscribe = firebase
        .firestore()
        .collection('mind-sweeps')
        .where('uid', '==', uid)
        .where('page', '==', 'inbox')
        .orderBy('updated', 'asc')
        .onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
          const mindSweeps = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            mindSweeps.unshift({
              key: doc.id,
              ...data,
            });
          });
          setData(mindSweeps);
          setLoading(false);
        }, (err) => {
          console.log(err);
          setError(err);
        });
      return () => unsubscribe();
    },
    [uid],
  );

  return {
    error,
    loading,
    data,
  };
}


// List archived mindSweeps
export function useArchivedMindSweeps({ uid }) {

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);


  React.useEffect(
    () => {
      setLoading(true);
      setError(false);

      const unsubscribe = firebase
        .firestore()
        .collection('mind-sweeps')
        .where('uid', '==', uid)
        // .where('page', '==', 'archived')
        .orderBy('updated', 'asc')
        .onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
          const mindSweeps = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            mindSweeps.unshift({
              key: doc.id,
              ...data,
            });
          });
          setData(mindSweeps);
          setLoading(false);
        }, (err) => {
          console.log(err);
          setError(err);
        });
      return () => unsubscribe();
    },
    [uid],
  );

  return {
    error,
    loading,
    data,
  };
}
