import { v4 as uuidv4 } from 'uuid';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CurrentMindSweepContext, UserContext } from '../../../database/context';
import { createMessage } from '../../../database/messages/create';
import { setMindSweep } from '../../../database/mind-sweeps/write';

function Guided({ inputElement }) {
  const { user } = useContext(UserContext);
  const { uid } = user;
  const { mindSweepKey } = useParams();
  const { data: mindSweep } = useContext(CurrentMindSweepContext);
  const [finish, setFinish] = useState(false);

  // current Time
  const [currentDateTime, setCurrentDateTime] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(() => setCurrentDateTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Check if enough data
  if (!mindSweep || !mindSweep.guided) {
    return null;
  };



  // Guided mindsweep data
  const { guided, currentQuestion, startTime } = mindSweep;
  const { questions } = guided;
  const lastQuestion = !questions[currentQuestion + 1];

  // Time calculations
  const endTime = new Date(startTime);
  endTime.setMinutes(endTime.getMinutes() + questions[currentQuestion].minutes);
  const shouldTake = endTime.valueOf() - startTime;
  const timeLeft = endTime.valueOf() - currentDateTime;
  const timePassed = shouldTake - timeLeft;
  const percentage = Math.ceil((timePassed / shouldTake) * 100);


  const nextQuestion = () => {
    if (!!lastQuestion) {
      console.log('last question');
      return null;
    }
    setMindSweep({
      mindSweepKey,
      fields: {
        uid,
        currentQuestion: currentQuestion + 1,
        startTime: Date.now(),
      }
    });

    // Add message with first question
    sendQuestion({ text: questions[currentQuestion + 1].question });
  }
  const sendQuestion = ({ text }) => {

    const fields = {
      uid,
      mindSweepKey,
      created: Date.now(),
      text,
      question: true,
    }

    console.log({
      messageKey: uuidv4(),
      fields,
      mindSweep,
    })

    createMessage({
      messageKey: uuidv4(),
      fields,
      mindSweep,
    })
    .catch((error) => {
      console.error(error);
    });

    inputElement.current.focus();
  }

  if (finish) {
    return null;
  }


  if (lastQuestion && percentage > 100) {
    return null;
  }


  return (
    <div>
      <pre className="hidden whitespace-pre-wrap p-2 rounded bg-gray-100 font-mono text-xs">{JSON.stringify(guided, null, 2) }</pre>

      <div>
        <div className="text-sm px-2 leading-none w-1/2">

          {
            !!lastQuestion
            && (
              <button
                type="button"
                className={`
                  flex flex-grow block w-full p-3 rounded-lg border-l font-bold text-left focus:outline-none
                  ${percentage >= 100 ? 'bg-blue-700 hover:bg-blue-800 text-white' : 'bg-gray-300 hover:bg-gray-400'}
                `}
                onClick={() => {
                  setFinish(true)
                }}
              >
                <div><i className="fa-solid fa-check"></i></div>
                <div className="ml-2">Finish</div>
              </button>
            )
          }

          {
            !lastQuestion
            && (
              <button
                type="button"
                className={`
                  flex flex-grow block w-full p-3 rounded-lg border-l font-bold text-left focus:outline-none
                  ${percentage >= 100 ? 'bg-blue-700 hover:bg-blue-800 text-white' : 'bg-gray-300 hover:bg-gray-400'}
                `}
                onClick={() => {
                  console.log('update mindsweep start-time and current-question');
                  nextQuestion();
                }}
              >
                <div><i className="fa-solid fa-angles-right"></i></div>
                <div className="ml-2">Next question</div>
              </button>
            )
          }
        </div>
      </div>

      {
        !!questions
        && questions.length 
        && (
          <div className="flex mt-2">
            {
              questions.map((question, index) => {
                // console.log(question);
                if (currentQuestion === index && percentage < 100) {
                  return (
                    <div className="flex-1 bg-gray-300 mx-2">
                      <div className="bg-green-400" style={{ height: '2px', width: `${percentage}%` }} />
                    </div>
                  )
                }
    
                if (currentQuestion === index && percentage >= 100) {
                  return (
                    <div className="flex-1 bg-gray-300 mx-2">
                      <div className="bg-green-400 w-full" style={{ height: '2px' }} />
                    </div>
                  )
                }
    
                if (currentQuestion > index) {
                  return (
                    <div className="flex-1 bg-gray-300 mx-2">
                      <div className="bg-blue-700 w-full" style={{ height: '2px' }} />
                    </div>
                  )
                }
    
                
                return (
                  <div className="flex-1 bg-gray-300 mx-2">
                  </div>
                )
    
              })
            }
          </div>
        )
      }
    </div>
  )
}


export default Guided;
