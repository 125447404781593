import React, { useContext } from 'react';
import TopBar from '../top-bar';
import { useParams } from 'react-router-dom';
import CreateGuidedMindSweepForm from './form';
import { UserContext } from '../../../database/context';
import { usePersonalGuidedMindSweep } from '../../../database/personal-guided-mind-sweep/read';

function CreateGuidedMindSweep(props) {
  const { personalGuidedMindSweepKey } = useParams();
  const { user } = useContext(UserContext);
  const { uid } = user;
  const { loading, data: personalGuidedMindSweep } = usePersonalGuidedMindSweep({ personalGuidedMindSweepKey, uid });

  if (loading) {
    return (
      <div className="flex-grow my-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }


  return (
    <div className="flex-1 w-full h-full flex bg-gray-200 xl:p-10">
      <div className="bg-slate-50 w-full h-full xl:max-w-4xl mx-auto flex flex-col relative shadow-xl">
        <div className="flex-shrink-0">
          <TopBar />
        </div>

        <CreateGuidedMindSweepForm personalGuidedMindSweep={personalGuidedMindSweep} />

      </div>
    </div>
  )
}

export default CreateGuidedMindSweep;